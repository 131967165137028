import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}</h2>
    <p>{`Use a Stepper as a guidance for users where they need to take multiple steps in
order to complete a task e.g. registrations, check-outs, etc. A Stepper
should've three steps or more.`}</p>
    <h2 {...{
      "id": "options",
      "style": {
        "position": "relative"
      }
    }}>{`Options`}</h2>
    <h3 {...{
      "id": "default-stepper",
      "style": {
        "position": "relative"
      }
    }}>{`Default Stepper`}</h3>
    <p>{`A Stepper always takes the 100% available width of its parent container.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=function hideEditor",
        "wrap": "function",
        "hideEditor": true
      }}>{`() => {
  const [currentStep, setCurrentStep] = React.useState(0);
  const [currentStep2, setCurrentStep2] = React.useState(0);

  return (
    <React.Fragment>
      <Box
        borderStyle="solid"
        borderWidth={1}
        borderColor="grey10"
        p={5}
        mb={8}
      >
        <Caption textAlign="center" color="grey50">
          Larger screens
        </Caption>
        <Stepper
          steps={['Step 1', 'Step 2', 'Step 3', 'Step 4']}
          activeStepIndex={currentStep}
          mt={5}
          mb={3}
        />
        <Flex justifyContent="center" mt={8}>
          <Tooltip id="previous-step1" label="Previous step">
            <IconButton
              as={ChevronBack}
              appearance="secondary"
              size="small"
              mr={5}
              onClick={() => setCurrentStep(Math.max(0, currentStep - 1))}
            />
          </Tooltip>
          <Tooltip id="next-step1" label="Next step">
            <IconButton
              as={ChevronForward}
              appearance="secondary"
              size="small"
              onClick={() => setCurrentStep(Math.min(4, currentStep + 1))}
            />
          </Tooltip>
        </Flex>
      </Box>
      <Flex justifyContent="center">
        <Box
          width="375px"
          borderStyle="solid"
          borderWidth={1}
          borderColor="grey10"
          p={5}
        >
          <Caption textAlign="center" color="grey50">
            Smaller screens
          </Caption>
          <Stepper
            steps={['Step 1', 'Step 2', 'Step 3', 'Step 4']}
            activeStepIndex={currentStep2}
            mt={5}
            mb={3}
          />
          <Flex justifyContent="center" mt={8}>
            <Tooltip id="previous-step2" label="Previous step">
              <IconButton
                as={ChevronBack}
                appearance="secondary"
                size="small"
                mr={5}
                onClick={() => setCurrentStep2(Math.max(0, currentStep2 - 1))}
              />
            </Tooltip>
            <Tooltip id="next-step2" label="Next step">
              <IconButton
                as={ChevronForward}
                appearance="secondary"
                size="small"
                onClick={() => setCurrentStep2(Math.min(4, currentStep2 + 1))}
              />
            </Tooltip>
          </Flex>
        </Box>
      </Flex>
    </React.Fragment>
  );
};
`}</code></pre>
    <h3 {...{
      "id": "text-flow-labels",
      "style": {
        "position": "relative"
      }
    }}>{`Text flow labels`}</h3>
    <p>{`Longer labels will wrap onto multiple lines if the length exceeds the available
space.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=function hideEditor",
        "wrap": "function",
        "hideEditor": true
      }}>{`() => {
  const [currentStep, setCurrentStep] = React.useState(0);
  const [currentStep2, setCurrentStep2] = React.useState(0);

  return (
    <React.Fragment>
      <Box
        borderStyle="solid"
        borderWidth={1}
        borderColor="grey10"
        p={5}
        mb={8}
      >
        <Caption textAlign="center" color="grey50">
          Larger screens
        </Caption>
        <Stepper
          steps={['Step 1', 'Step 2 with a longer label', 'Step 3', 'Step 4']}
          activeStepIndex={currentStep}
          mt={5}
          mb={3}
        />
        <Flex justifyContent="center" mt={8}>
          <Tooltip id="previous-step1" label="Previous step">
            <IconButton
              as={ChevronBack}
              appearance="secondary"
              size="small"
              mr={5}
              onClick={() => setCurrentStep(Math.max(0, currentStep - 1))}
            />
          </Tooltip>
          <Tooltip id="next-step1" label="Next step">
            <IconButton
              as={ChevronForward}
              appearance="secondary"
              size="small"
              onClick={() => setCurrentStep(Math.min(4, currentStep + 1))}
            />
          </Tooltip>
        </Flex>
      </Box>
      <Flex justifyContent="center">
        <Box
          width="375px"
          borderStyle="solid"
          borderWidth={1}
          borderColor="grey10"
          p={5}
        >
          <Caption textAlign="center" color="grey50">
            Smaller screens
          </Caption>
          <Stepper
            steps={['Step 1', 'Step 2 with a longer label', 'Step 3', 'Step 4']}
            activeStepIndex={currentStep2}
            mt={5}
            mb={3}
          />
          <Flex justifyContent="center" mt={8}>
            <Tooltip id="previous-step2" label="Previous step">
              <IconButton
                as={ChevronBack}
                appearance="secondary"
                size="small"
                mr={5}
                onClick={() => setCurrentStep2(Math.max(0, currentStep2 - 1))}
              />
            </Tooltip>
            <Tooltip id="next-step2" label="Next step">
              <IconButton
                as={ChevronForward}
                appearance="secondary"
                size="small"
                onClick={() => setCurrentStep2(Math.min(4, currentStep2 + 1))}
              />
            </Tooltip>
          </Flex>
        </Box>
      </Flex>
    </React.Fragment>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "guidelines",
      "style": {
        "position": "relative"
      }
    }}>{`Guidelines`}</h2>
    <ul>
      <li parentName="ul">{`Always use clear and short labels that describe the purpose of the steps.`}</li>
      <li parentName="ul">{`A Stepper should've at least three steps. If there are less than three,
consider using a button labelled "Next".`}</li>
      <li parentName="ul">{`If a specific proces needs more than 5 steps, consider simplifying the
process.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      